var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card_list_wrap" }, [
    _c(
      "div",
      { staticClass: "card_list" },
      [
        _vm._l(_vm.items, function(goods, goodsIndex) {
          return [
            _c("top-goods-card", {
              key: goods.id || goods.goodsId,
              attrs: { data: goods, index: goodsIndex }
            })
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }