var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topGoodsCard_card" }, [
    _c(
      "div",
      {
        class: { card: true, isLast: (_vm.index + 1) % 2 == 0 },
        on: {
          click: function($event) {
            return _vm.toDetail(_vm.data.goodsId || _vm.data.id)
          }
        }
      },
      [
        _c("div", { staticClass: "img_main" }, [
          _c("div", { staticClass: "tags" }, [
            _c("div", [_vm._v("TOP")]),
            _c("div", [_vm._v(_vm._s(_vm.index + 1))])
          ]),
          _c("img", {
            attrs: { src: _vm.data.mainPic + "_310x310.jpg", alt: "商品主图" }
          }),
          _vm.data.brandName
            ? _c("div", { staticClass: "brand" }, [
                _vm._v(_vm._s(_vm.data.brandName))
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "card_right" }, [
          _c("div", [
            _c("div", { staticClass: "search_card_title" }, [
              _c("span", { style: { verticalAlign: "middle" } }, [
                _c("img", {
                  attrs: {
                    src: _vm.getShopImg(
                      _vm.data.haitao,
                      _vm.data.tchaoshi,
                      _vm.data.shopType
                    ),
                    alt: ""
                  }
                })
              ]),
              _vm.data.lowest && _vm.data.lowest != "0.00"
                ? _c("span", { staticClass: "lowest" }, [
                    _c("img", {
                      attrs: { src: require("../images/lowest.png"), alt: "" }
                    }),
                    _vm._v(" 30天最低价 ")
                  ])
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_vm.data.dtitle || _vm.data.title))])
            ]),
            _c("div", { staticClass: "lq_special_text" }, [
              _vm.data.specialText && _vm.data.specialText[0]
                ? _c(
                    "span",
                    {
                      staticClass: "sp_text",
                      attrs: {
                        title: _vm.data.specialText && _vm.data.specialText[0]
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.data.specialText && _vm.data.specialText[0]
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.data.couponPrice
                ? _c("span", { staticClass: "quan" }, [
                    _vm._v(_vm._s(_vm.data.couponPrice) + "元券")
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", [
            _c("div", { staticClass: "priceWrap" }, [
              _c("div", { staticClass: "price" }, [
                _c("span", { staticClass: "price_ac" }, [
                  _vm._v("券后"),
                  _c("b", { staticClass: "m" }, [_vm._v("￥")]),
                  _c("b", [_vm._v(_vm._s(_vm.data.actualPrice))])
                ]),
                _c("span", { staticClass: "price_or" }, [
                  _vm._v("￥" + _vm._s(_vm.data.originalPrice))
                ])
              ]),
              _vm._m(0)
            ]),
            _c("span", { staticClass: "sales" }, [
              _c("img", {
                attrs: { src: require("../images/sales.png"), alt: "" }
              }),
              _c("span", [
                _vm._v(
                  "近30天售出" +
                    _vm._s(_vm._f("salesNum")(_vm.data.monthSales, "w", 1)) +
                    "件"
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "qiang_btn" }, [
      _c("div", { staticClass: "qiang" }, [_vm._v("马上抢")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }