<template>
  <div class="topGoodsCard_card">
    <div
      :class="{ card: true, isLast: (index + 1) % 2 == 0 }"
      @click="toDetail(data.goodsId || data.id)"
    >
      <div class="img_main">
        <div class="tags">
          <div>TOP</div>
          <div>{{ index + 1 }}</div>
        </div>
        <img :src="`${data.mainPic}_310x310.jpg`" alt="商品主图" />
        <div class="brand" v-if="data.brandName">{{data.brandName}}</div>
      </div>
      <div class="card_right">
        <div>
          <div class="search_card_title">
            <span :style="{ verticalAlign: 'middle' }"
              ><img
                :src="getShopImg(data.haitao, data.tchaoshi, data.shopType)"
                alt=""
            /></span>
            <span class="lowest" v-if="data.lowest && data.lowest != '0.00'">
              <img src="../images/lowest.png" alt="" />
              30天最低价
            </span>
            <span>{{ data.dtitle || data.title }}</span>
          </div>
          <div class="lq_special_text">
            <span
              class="sp_text"
              :title="data.specialText && data.specialText[0]"
              v-if="data.specialText && data.specialText[0]"
            >
              {{ data.specialText && data.specialText[0] }}
            </span>
            <span class="quan" v-if="data.couponPrice"
              >{{ data.couponPrice }}元券</span
            >
          </div>
        </div>
        <div>
          <div class="priceWrap">
            <div class="price">
              <span class="price_ac"
                >券后<b class="m">￥</b><b>{{ data.actualPrice }}</b></span
              >
              <span class="price_or">￥{{ data.originalPrice }}</span>
            </div>
            <div class="qiang_btn">
              <div class="qiang">马上抢</div>
              <!-- <div class="qianged">
              {{ data.monthSales | salesNum("w") }}人已抢
            </div> -->
            </div>
          </div>
          <span class="sales">
            <img src="../images/sales.png" alt="" />
            <span>近30天售出{{ data.monthSales | salesNum("w",1) }}件</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number,
  },
  methods: {
    getShopImg(haitao, tchaoshi, shopType) {
      if (haitao == 1) return require("../../../assets/search/prod_icon2.png");
      if (tchaoshi == 1)
        return require("../../../assets/search/prod_icon3.png");
      if (shopType == 1)
        return require("../../../assets/search/prod_icon1.png");
      return require("../../../assets/search/prod_icon1.png");
    },
    toDetail(id) {
      try {
        DTKsatc.eventStat({
          name: "moveColumnClickEvent",
          params: {
            desc: "运营配置栏目点击",
            columnId: this.$route.query.id,
            objectId: id,
          },
        });
      } catch (err) {}
      window.open(`?r=/l/d&id=${id}&from=dtk`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.topGoodsCard_card {
  .card.isLast {
    margin-right: 0;
  }
  .card {
    display: flex;
    background: #fff;
    border-radius: 5px;
    width: 530px;
    padding: 10px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      .search_card_title span {
        color: #fe2e54 !important;
      }
    }
    .img_main {
      position: relative;
      .tags {
        background: url("../images/top_tags.png") no-repeat;
        background-size: 100% 100%;
        width: 37px;
        height: 45px;
        position: absolute;
        top: -10px;
        left: 0;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        padding-top: 5px;
        box-sizing: border-box;
        color: #ffffff;
        > div {
          line-height: 1.2;
        }
      }
      img {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        display: block;
      }
      .brand {
        height: 27px;
        background: #f48728;
        border-radius: 0 14.8px 0 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        padding: 5px;
        box-sizing: border-box;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 1;
      }
    }
    .card_right {
      flex-grow: 1;
      display: flex;
      padding: 5px 0;
      box-sizing: border-box;
      margin-left: 16px;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 10px;
      .search_card_title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        span.lowest {
          width: 71px;
          height: 14px;
          background: #ffeee0;
          border-radius: 2px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 10px;
          color: #ff7301;
          white-space: nowrap;
          padding: 1px 3px;
          box-sizing: border-box;
          margin-right: 10px;
          img {
            vertical-align: sub;
          }
        }
        span {
          // vertical-align: middle;
          margin-left: 2px;
          font-size: 15px;
          color: #333;
        }
        img {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
          border-radius: 0;
          margin-right: 4px;
        }
      }
      .lq_special_text {
        display: flex;
        text-align: center;
        margin-top: 8px;
        span {
          padding: 2px 6px;
          width: 54px;
          height: 20px;
          line-height: 20px;
          background: rgba(255, 210, 210, 0.71);
          border-radius: 2.82px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 14.08px;
          color: #ea000e;
        }
        span.sp_text {
          width: auto;
          color: #be5d2f;
          background: #fff2d8;
          margin-right: 7px;
        }
      }
      .priceWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .price {
          .price_ac {
            font-size: 14px;
            color: #ed1c24;
            b.m {
              font-size: 17px;
            }
            b {
              font-size: 27px;
              margin: 0 4px 0 0;
            }
          }
          .price_or {
            font-size: 14px;
            color: #ababab;
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
        .qiang_btn {
          width: 82px;
          height: 28px;
          background: #ff2b22;
          border-radius: 14.8px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .qiang {
            font-size: 16px;
          }
          .qianged {
            font-size: 13px;
          }
        }
      }
      .sales {
        height: 28px;
        background: #ffeee0;
        border-radius: 14.8px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 15px;
        padding: 2px 11px;
        box-sizing: border-box;
        color: #ff2b22;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }
      }
    }
  }
}
</style>