<template>
  <div class="card_list_wrap">
    <div class="card_list">
      <template v-for="(goods, goodsIndex) in items">
        <top-goods-card :data="goods" :key="goods.id || goods.goodsId" :index="goodsIndex" />
      </template>
    </div>
  </div>
</template>

<script>
import TopGoodsCard from "./topGoodsCard.vue";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TopGoodsCard,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.card_list_wrap {
  .card_list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>