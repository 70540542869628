var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "topList_container",
      style: { background: _vm.data.background }
    },
    [
      _vm._l(_vm.data.floor, function(item) {
        return [
          item.type === 0 && item.config.banner && item.config.banner.length
            ? _c("div", { key: item.id, staticClass: "topList_banner" }, [
                item.config.banner.length
                  ? _c("img", {
                      attrs: {
                        src: item.config.banner[0] && item.config.banner[0].pic,
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ]
      }),
      _vm.showNav
        ? _c(
            "div",
            { staticClass: "main_wrap" },
            [
              _c("div", { staticClass: "swiper" }, [
                _c(
                  "div",
                  {
                    staticClass: "swiper-wrapper",
                    style: {
                      justifyContent:
                        _vm.data.length < 5 ? "center" : "inherit",
                      alignItems: _vm.data.length < 5 ? "center" : "inherit"
                    }
                  },
                  _vm._l(_vm.data.floor, function(item, swiperIndex) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.type != 0,
                            expression: "item.type != 0"
                          }
                        ],
                        key: item.id,
                        staticClass: "swiper-slide",
                        class: _vm.swiperActive == swiperIndex ? "act" : "",
                        on: {
                          click: function($event) {
                            return _vm.changeNav(swiperIndex)
                          }
                        }
                      },
                      [
                        _vm.swiperActive == swiperIndex
                          ? _c("img", {
                              staticClass: "nav_icon",
                              attrs: {
                                src: require("../images/nav_icon.png"),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(item.title.slice(0, 6)) + " ")
                      ]
                    )
                  }),
                  0
                )
              ]),
              _c("top-card-list", {
                attrs: {
                  items:
                    _vm.list[_vm.swiperActive] &&
                    _vm.list[_vm.swiperActive].list
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "main_wrap" },
            [
              _c("top-card-list", {
                attrs: {
                  items:
                    _vm.list[_vm.swiperActive] &&
                    _vm.list[_vm.swiperActive].list
                }
              })
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }